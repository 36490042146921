import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ResetPasswordForm from "../components/Forms/ResetPasswordForm"
import withLocation from "../components/HOC/withLocation"

const ResetPassword = ({ location }) => {
  const query = new URLSearchParams(location.search)
  const data = query.get("key")
  return (
    data && (
      <Layout location="login">
        <SEO title="Login" />
        <div className="wrapper-home">
          <h1>Login</h1>
          <ResetPasswordForm data={data} />
        </div>
      </Layout>
    )
  )
}

export default withLocation(ResetPassword)
